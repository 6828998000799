// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-post-jsx": () => import("./../../src/components/BlogPost.jsx" /* webpackChunkName: "component---src-components-blog-post-jsx" */),
  "component---src-pages-algemene-voorwaarden-js": () => import("./../../src/pages/algemene-voorwaarden.js" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diensten-js": () => import("./../../src/pages/diensten.js" /* webpackChunkName: "component---src-pages-diensten-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-niche-relaties-js": () => import("./../../src/pages/niche-relaties.js" /* webpackChunkName: "component---src-pages-niche-relaties-js" */),
  "component---src-pages-over-js": () => import("./../../src/pages/over.js" /* webpackChunkName: "component---src-pages-over-js" */)
}

